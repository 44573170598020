<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                    <label>
                      Name
                      <span>*</span>
                    </label>
                    <input type="text" v-model="dispatch.name" placeholder="Name Character limit 30" :class="errors[0] ? 'warning-border':''" required="required" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModeOfDispatch",
  data(){
    return {
      max:30,
      dispatch:{
        name : ""
      }
    }
  },
  methods: {
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/dispatch/",this.dispatch)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0;
}
.error-highlight{
  color:red;
}
</style>