<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
          <tr>
            <th colspan="2">Name</th>
          </tr>
          <tr v-for="(data,i) in dispatch" :key="i">
            <td>
              <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max"
                  v-model="data.name"
                  :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''"
                />
              </div>
              </ValidationProvider>
            </td>
            <td class="width_50">
              <a class="table_action_btn" title="DELETE" @click="deleteDispatch(data.id)">DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="data.is_edit_mode"
                @click="cancelDispatch()"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                v-if="data.is_edit_mode"
                @click="updateDispatch(data)"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                @click="data.is_edit_mode = true"
                v-if="!data.is_edit_mode"
              >EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModeOfDispatch",
  components: {},
  data() {
    return {
      max:30,
      makeEditable: false,
      cancelEditable: true,
      dispatch: []
    };
  },
  created() {
    this.fetchDispatch();
  },
  methods: {
    fetchDispatch() {
   
      this.axios
        .get("/api/dispatch/")
        .then(response => {
          this.dispatch = response.data.mode_of_dispatch.map(element => {
            element.is_edit_mode = false;
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateDispatch(data) {
      this.$refs.observer.validate();
      this.axios
        .put("/api/dispatch/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log("error", error.response);
        });
    },
    deleteDispatch(id) {
      if (confirm("Are you sure you want to delete this mode of dispatch?")) {
        this.axios
          .delete("/api/dispatch/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchDispatch();
          })
          .catch(error => {
            console.log("error", error.response);
            this.toast.error();
          });
      }
    },
    cancelDispatch(){
      this.fetchDispatch();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 10px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 0 5px;
}

.width_50 {
  width: 50%;
}
.righted {
  text-align: right;
}
</style>